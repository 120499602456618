export function RealTimePrice(product, clientGroup) {
	if (product) {
		// corporate client
		if (clientGroup?._id) {
			if (clientGroup._id === "1") {
				return +product.price?.group1
					? +product.price?.group1
					: +product.price?.sale
					? +product.price?.sale
					: +product.price?.basic;
			}
			if (clientGroup._id === "2") {
				return +product.price?.group2
					? +product.price?.group2
					: +product.price?.sale
					? +product.price?.sale
					: +product.price?.basic;
			}
			if (clientGroup._id === "3") {
				return +product.price?.group3
					? +product.price?.group3
					: +product.price?.sale
					? +product.price?.sale
					: +product.price?.basic;
			}
			return 0;
		}
		// sale
		if (product.price?.sale) {
			return +product.price.sale;
		}
		// basic
		return +product.price?.basic;
	}
	return 0;
}
