import axios from 'axios';
import { cookies } from './cookies';
import config from '../config';
// import { loadProgressBar } from 'axios-progress-bar';
// import 'axios-progress-bar/dist/nprogress.css';

// loadProgressBar()

const API = axios.create({
  baseURL: `${config.api.API_URL}/${config.api.API_Version}/`,
});

const settings = {
  //   onUploadProgress: function (progressEvent) {
  //     var percentCompleted = Math.round(
  //       (progressEvent.loaded * 100) / progressEvent.total
  //     );
  //     console.log(percentCompleted);
  //   },
  // onDownloadProgress: progressEvent => {
  //     console.log(progressEvent);
  //     const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
  //     const current = progressEvent.currentTarget.response.length

  //     let percentCompleted = Math.floor(current / total * 100)
  //     console.log('completed: ', percentCompleted)
  //   },
  headers: {
    'private-token': cookies.get(config.authentication.tokenAddress),
  },
};

export function get(route, query, sort, uploadType, limit, NoNotAvailable, populate) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    let completeRoute = `${route}${query ? `?q=${JSON.stringify(query)}` : ''}${
      sort ? `&sort=${JSON.stringify(sort)}` : ''
    }${uploadType ? `&uploadType=${JSON.stringify(uploadType)}` : ''}${
      limit ? `&limit=${JSON.stringify(limit)}` : ''
    }${
      NoNotAvailable ? `&NoNotAvailable=${JSON.stringify(NoNotAvailable)}` : ''
    }${
      populate ? `&populate=${JSON.stringify(populate)}` : ''
    }`;
    if (route && completeRoute && completeRoute.length !== 0) {
      API.get(completeRoute, settings)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }
  });
}
export function getBlob(route, query, sort, uploadType, limit) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    let completeRoute = `${route}${query ? `?q=${JSON.stringify(query)}` : ''}${
      sort ? `&sort=${JSON.stringify(sort)}` : ''
    }${uploadType ? `&uploadType=${JSON.stringify(uploadType)}` : ''}${
      limit ? `&limit=${JSON.stringify(limit)}` : ''
    }`;
    if (route && completeRoute && completeRoute.length !== 0) {
      API.post(completeRoute, { ...settings, responseType: 'blob' })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    }
  });
}
export function getById(route, id) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.get(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function post(route, data) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.post(route, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function postBlob(route, data) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.post(route, data, { ...settings, responseType: 'blob' })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function update(route, id, data) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.put(`${route}/${id}`, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function remove(route, id) {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.delete(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export const upload = (
  id,
  files,
  lang,
  destiny,
  thumbSizeWidth,
  thumbSizeHeight,
  responsiveImages
) => {
  settings.headers['private-token'] = cookies.get(
    config.authentication.tokenAddress
  );
  return Promise.all(
    files.map((file, index) => {
      const formData = new FormData();
      formData.append('obj_id', id);
      formData.append('file', file);
      formData.append('lang', lang);
      formData.append('destiny', destiny);
      formData.append('thumbSizeWidth', thumbSizeWidth);
      formData.append('thumbSizeHeight', thumbSizeHeight);
      formData.append('responsiveImages', responsiveImages);
      return new Promise((resolve, reject) => {
        API.post(config.api.uploads, formData, settings)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    })
  );
};

export default API;
