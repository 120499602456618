import React, { useEffect, useState } from 'react';
import DataTable, { defaultThemes } from 'react-data-table-component';
import differenceBy from 'lodash/differenceBy';
import { useLang } from '../../helpers/language';

export function TableComponent(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState(false);
  const { lang } = useLang();
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    setSelectedRows(selectedRows);
  };
  const rowSelectCritera = (row) => {
    return row._id > 5;
  };
  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.name[lang]
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(props.data, selectedRows, 'name.hy'));
      }
    };

    return (
      <button
        onClick={handleDelete}
        style={{ backgroundColor: 'red' }}
        // icon
        className='btn btn-danger'
      >
        Delete
      </button>
    );
    // eslint-disable-next-line
  }, [props, selectedRows, toggleCleared]);

  useEffect(() => {
    props.setter && props.setter(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className='col-md-12'>
      {/* {props.setter ? <button className="btn btn-primary" onClick={add}>Add to table</button> : null } */}
      {props?.data?.constructor?.name === 'Array' && (
        <DataTable
          columns={props.columns}
          data={props.data}
          title={props.title}
          selectableRows={props.setter ? true : false}
          selectableRowsHighlight={props.setter ? true : false}
          selectableRowsNoSelectAll={props.setter ? true : false}
          onSelectedRowsChange={handleChange}
          selectableRowSelected={rowSelectCritera}
          conditionalRowStyles={props.conditionalRowStyles}
          responsive
          clearSelectedRows={toggleCleared}
          contextActions={contextActions}
          highlightOnHover
          pagination={props.pagination ? true : false}
          customStyles={props.customStyles ? customStyles : false}
          striped
        />
      )}
    </div>
  );
  // }
  // return null
}
