const config = {
	api: {
		aboutUsPage: 'about-us-page',
		aboutUsPrivileges: 'about-us-privileges',
		banner: 'banner',
		contactPage: 'contact-page',
		contactPart: 'contact-part',
		contactType: 'contact-type',
		corporatePage: 'corporate-page',
		socialLink: 'social-link',
		category: 'category',
		client: 'client',
		deliveryPage: 'delivery-page',
		fishcoinPage: 'fishcoin-page',
		market: 'market',
		order: 'order',
		product: 'product',
		privacyPolicy: 'privacy-policy',
		tag: 'tag',
		termOfService: 'terms-of-service',
		user: 'user',
		chartsPage: 'charts-page',

		uploads: 'uploads',
		API_URL: process.env.REACT_APP_API_URL,
		API_Version: 'v4',
		CHAT_URL: process.env.REACT_APP_CHAT_URL,
		MAINAPP_URL: process.env.REACT_APP_MAINAPP_URL,
	},
	CLIENT_GROUPS: [
		{
			_id: '1',
			name: {
				en: 'Group 1',
				ru: 'Группа 1',
				hy: 'Խումբ 1',
			},
		},
		{
			_id: '2',
			name: {
				en: 'Group 2',
				ru: 'Группа 2',
				hy: 'Խումբ 2',
			},
		},
		{
			_id: '3',
			name: {
				en: 'Group 3',
				ru: 'Группа 3',
				hy: 'Խումբ 3',
			},
		},
	],
	deliveryPrice: 500,
	authentication: {
		login: '/login/private',
		verify: '/verify/private',
		tokenAddress: 'private-token',
	},
	brand: {
		name: 'ICEFISH',
		link: 'https://www.icefish.am',
		logo: '/white_logo.svg',
	},
	translate: {
		dropzoneText: {
			en: 'There is no file, click to upload a new one',
			ru: 'Файла нет, нажмите, чтобы загрузить новыйe',
			hy: 'Ֆայլ չկա, սեղմեք՝ նորը վերբեռնելու համար',
		},
		linkCopied: {
			en: 'Link copied',
			ru: 'Ссылка скопирована',
			hy: 'Լինկը պատճենվել է',
		},
		changedDate: {
			en: 'Change Date',
			ru: 'Дата изменения',
			hy: 'Փոփոխության ամսաթիվը',
		},
		user: {
			en: 'User',
			ru: 'Пользователь',
			hy: 'Օգտատեր',
		},
		historyTable: {
			en: 'History table',
			ru: 'Таблица истории',
			hy: 'Պատմության աղյուսակ',
		},
		banner: {
			en: 'Banner',
			ru: 'Баннер',
			hy: 'Բաններ',
		},
		ordersCart: {
			en: 'Orderes chart',
			ru: 'График заказов',
			hy: 'Պատվերների գծապատկեր',
		},
		quantity: {
			en: 'Quantity',
			ru: 'Количество',
			hy: 'Քանակ',
		},
		color: {
			en: 'Color',
			ru: 'Цвет',
			hy: 'Գույն',
		},
		addToList: {
			en: 'Add to list',
			ru: 'Добавить в лист',
			hy: 'Ավելացնել լիստում',
		},
		noContent: {
			en: 'There is no content',
			ru: 'Нет контента',
			hy: 'Բովանդակություն չկա',
		},
		paymentMethod: {
			en: 'Payment method',
			ru: 'Метод оплаты',
			hy: 'Վճարման եղանակ',
		},
		paymentSystem: {
			en: 'Payment System',
			ru: 'Платежная система',
			hy: 'Վճարային համակարգ',
		},
		deliveryMethod: {
			en: 'Delivery method',
			ru: 'Способ доставки',
			hy: 'Առաքման եղանակը',
		},
		deliveryAddress: {
			en: 'Delivery Address',
			ru: 'Адресс доставки',
			hy: 'Առաքման հասցե',
		},
		placeholder: {
			en: 'Input in latin',
			ru: 'Ввод на кирилице',
			hy: 'Մուտքագրում հայատառ',
		},
		username: {
			en: 'Username',
			ru: 'Имя пользователя',
			hy: 'Օգտատիրոջ անուն',
		},
		login: {
			en: 'Sign in to start a session',
			ru: 'Войдите, чтобы начать сеанс',
			hy: 'Մուտք գործեք՝ սկսելու համար',
		},
		loginButton: {
			en: 'Log in',
			ru: 'Войти',
			hy: 'Մուտք',
		},
		minute: {
			en: 'minute',
			ru: 'минута',
			hy: 'րոպե',
		},
		reset: {
			en: 'Reset',
			ru: 'Збросить',
			hy: 'Չեղարկել',
		},
		notifications: {
			en: 'Notifications',
			ru: 'Уведомления',
			hy: 'Ծանուցումները',
		},
		supportText: {
			en: 'For all questions write',
			ru: 'По всем вопросам пишите',
			hy: 'Բոլոր հարցերի համար գրեք',
		},
		incorrectLogin: {
			en: 'You entered an incorrect username or password',
			ru: 'Вы ввели неверный логин или пароль',
			hy: 'Դուք մուտքագրել եք սխալ օգտվողի անուն կամ գաղտնաբառ',
		},
		address: {
			en: 'Address',
			ru: 'Адрес',
			hy: 'Հասցե',
		},
		dashboard: {
			en: 'Dashboard',
			ru: 'Доска',
			hy: 'Վահանակ',
		},
		password: {
			en: 'Password',
			ru: 'Пароль',
			hy: 'Գաղտնաբառ',
		},
		href: {
			en: 'Href',
			ru: 'Ссылка',
			hy: 'Հղում',
		},
		name: {
			en: 'Name',
			ru: 'Имя',
			hy: 'Անուն',
		},
		firstName: {
			en: 'First Name',
			ru: 'Имя',
			hy: 'Անուն',
		},
		lastName: {
			en: 'Last Name',
			ru: 'Фамилия',
			hy: 'Ազգանուն',
		},
		dateOfBirth: {
			en: 'Date Of Birth',
			ru: 'Дата рождения',
			hy: 'Ծննդյան ամսաթիվ',
		},
		desc: {
			en: 'Description',
			ru: 'Оисание',
			hy: 'Նկարագրություն',
		},
		subDesc: {
			en: 'Sub Description',
			ru: 'Под описание',
			hy: 'Ենթա նկարագրություն',
		},
		cannotPayOnline: {
			en: 'Cannot pay online',
			ru: 'Невозможно оплатить онлайн',
			hy: 'Հնարավոր չէ վճարել առցանց',
		},
		notAvailable: {
			en: 'Product not available',
			ru: 'Продукт недоступен',
			hy: 'Ապրանքը հասանելի չէ',
		},
		role: {
			en: 'Role',
			ru: 'Роль',
			hy: 'Դեր',
		},
		roles: [
			{
				_id: 'admin',
				name: { en: 'Admin', ru: 'Администратор', hy: 'Ադմին' },
			},
			{
				_id: 'manager',
				name: { en: 'Manager', ru: 'Управляющий', hy: 'Մենեջեր' },
			},
			{
				_id: 'editor',
				name: { en: 'Editor', ru: 'Редактор', hy: 'Խմբագիր' },
			},
		],
		rate: {
			en: 'Rate',
			ru: 'Показатель',
			hy: 'Գործակից',
		},
		price: {
			en: 'Price',
			ru: 'Цена',
			hy: 'Գին',
		},
		orderPrice: {
			en: 'Products total price',
			ru: 'Общая цена товаров',
			hy: 'Ապրանքների ընդհանուր գին',
		},
		orderTotalPrice: {
			en: 'Order total price',
			ru: 'Общая цена заказа',
			hy: 'Պատվերի ընդհանուր գին',
		},
		fishcoinForSpend: {
			en: 'Spended Fishcoin',
			ru: 'Использовано Fishcoin',
			hy: 'Օգտագործված Fishcoin',
		},
		cookPriceTotal: {
			en: 'Cook Price',
			ru: 'Сумма за приготовление',
			hy: 'Պատրաստման գումար',
		},
		needCook: {
			en: 'Need cook',
			ru: 'Требуется готовка',
			hy: 'Հարկավոր է պատրաստել',
		},
		totlaPrice: {
			en: 'Total Price',
			ru: 'Общая Цена',
			hy: 'Ընդհանուր Գին',
		},
		deliveryPrice: {
			en: 'Delivery price',
			ru: 'Цена за доставку',
			hy: 'Առաքման գին',
		},
		company: {
			en: 'Company',
			ru: 'Компания',
			hy: 'Ընկերություն',
		},
		phone: {
			en: 'Phone',
			ru: 'Телефон',
			hy: 'Հեռախոս',
		},
		priceBasic: {
			en: 'Price Basic',
			ru: 'Базовая цена',
			hy: 'Հիմնական գինը',
		},
		group1: {
			en: 'Group 1 price',
			ru: 'Цена Группы 1',
			hy: 'Խումբ 1 գինը',
		},
		group2: {
			en: 'Group 2 price',
			ru: 'Цена Группы 2',
			hy: 'Խումբ 2 գինը',
		},
		group3: {
			en: 'Group 3 price',
			ru: 'Цена Группы 3',
			hy: 'Խումբ 3 գինը',
		},
		sale: {
			en: 'Sale price',
			ru: 'Скидочная цена',
			hy: 'Զեղչված գին',
		},
		salePriceBasic: {
			en: 'Sale Price',
			ru: 'Скидочная цена',
			hy: 'Զեղչված գին',
		},
		startDate: {
			en: 'Start Date',
			ru: 'Дата начала',
			hy: 'Մեկնարկի ամսաթիվ',
		},
		endDate: {
			en: 'End Date',
			ru: 'Дата окончания',
			hy: 'Ավարտի ամսաթիվ',
		},
		status: {
			en: 'Status',
			ru: 'Статус',
			hy: 'Ստատուս',
		},
		paidStatus: {
			en: 'Paid Status',
			ru: 'Статус оплаты',
			hy: 'Վճարման ստատուս',
		},
		client: {
			en: 'Client',
			ru: 'Клиент',
			hy: 'Հաճախորդ',
		},
		city: {
			en: 'City',
			ru: 'Город',
			hy: 'Քաղաք',
		},
		genders: [
			{
				name: {
					en: 'Male',
					ru: 'Мужчина',
					hy: 'Արական',
				},
			},
			{
				name: {
					en: 'Female',
					ru: 'Женщина',
					hy: 'իգական',
				},
			},
		],
		gender: {
			en: 'Gender',
			ru: 'Пол',
			hy: 'Սեռ',
		},
		familyStatuses: [
			{
				name: {
					en: 'Single',
					ru: 'Одинокий',
					hy: 'Միայնակ',
				},
			},
			{
				name: {
					en: 'Married',
					ru: 'В браке',
					hy: 'Ամուսնացած',
				},
			},
		],
		more: {
			en: 'Additional',
			ru: 'Дополнительный',
			hy: 'Լրացուցիչ',
		},
		addNew: {
			en: 'Add New',
			ru: 'Добавить',
			hy: 'Ավելացնել',
		},
		create: {
			en: 'Order',
			ru: 'Заказ',
			hy: 'Պատվեր',
		},
		save: {
			en: 'Save',
			ru: 'Сохранить',
			hy: 'Պահպանել',
		},
		sent: {
			en: 'Sent',
			ru: 'Отправленно',
			hy: 'Ուղարկված է',
		},
		send: {
			en: 'Send',
			ru: 'Отправить',
			hy: 'Ուղարկել',
		},
		open: {
			en: 'Open',
			ru: 'Открыть',
			hy: 'Բացել',
		},
		remove: {
			en: 'Remove',
			ru: 'Удалит',
			hy: 'Ջնջել',
		},
		cancel: {
			en: 'Cancel',
			ru: 'Отменить',
			hy: 'Չեղարկել',
		},
		edit: {
			en: 'Edit',
			ru: 'Редактировать',
			hy: 'Խմբագրել',
		},
		write: {
			en: 'Write',
			ru: 'Написать',
			hy: 'Գրել',
		},
		selectSomething: {
			en: 'Select something',
			ru: 'Выберите что-нибудь',
			hy: 'Ընտրեք մի բան',
		},
		close: {
			en: 'Close',
			ru: 'Закрыть',
			hy: 'Փակել',
		},
		closeTicket: {
			en: 'Close Ticket',
			ru: 'Закрыть билет',
			hy: 'Փակել տոմսը',
		},
		index: {
			en: 'Index',
			ru: 'Индекс',
			hy: 'Ինդեքս',
		},
		developed: {
			en: 'Developed',
			ru: 'Разработанно',
			hy: 'Ստեղծված է',
		},
		version: {
			en: 'Version',
			ru: 'Версия',
			hy: 'Տարբերակ',
		},
		mainInformation: {
			en: 'Basic information',
			ru: 'Основная информация',
			hy: 'Հիմնական տեղեկատվություն',
		},
		menu: {
			order: {
				name: { en: 'Order', ru: 'Заказ', hy: 'Պատվեր' },
				icon: 'list-order-svgrepo-com.svg',
				route: 'order',
			},
			orders: { en: 'Orders', ru: 'Заказы', hy: 'Պատվերներ' },
			clientGroup: {
				en: 'Client Groups',
				ru: 'Группы клиентов',
				hy: 'Հաճախորդների խմբեր',
			},
			clients: {
				en: 'Clients',
				ru: 'Клиенты',
				hy: 'Հաճախորդներ',
			},
			kitchenCategories: {
				en: 'Kitchen Categories',
				ru: 'Категории кухнии',
				hy: 'Խոհանոցի կատեգորիաներ',
			},
			kitchenProducts: {
				en: 'Kitchen products',
				ru: 'Продукты кухнии',
				hy: 'Խոհանոցի ապրանքներ',
			},
			storeCategories: {
				en: 'Store Categories',
				ru: 'Категории магазина',
				hy: 'Խանութի կատեգորիաներ',
			},
			storeProducts: {
				en: 'Store products',
				ru: 'Продукты кухнии',
				hy: 'Խանութի ապրանքներ',
			},
			sales: {
				en: 'Sales',
				ru: 'Продажы',
				hy: 'Վաճառքներ',
			},
			failed: {
				en: 'Failed',
				ru: 'Не удалось',
				hy: 'Ձախողվեց',
			},
			serving: {
				en: 'Serving',
				ru: 'Обслуживание',
				hy: 'Մատուցում',
			},
		},
	},
	menu: {
		primary: {
			order: {
				name: { en: 'Order', ru: 'Заказ', hy: 'Պատվեր' },
				icon: 'list-order-svgrepo-com.svg',
				route: 'order',
				roles: ['admin', 'manager'],
			},
			assortment: {
				name: { en: 'Assortment', ru: 'Ассортимент', hy: 'Տեսականի' },
				icon: 'folder-svgrepo-com.svg',
				collapse: [
					{
						name: {
							en: 'Categories',
							ru: 'Категории',
							hy: 'Կատեգորիաներ',
						},
						icon: 'list-svgrepo-com.svg',
						route: 'category',
					},
					{
						name: { en: 'Products', ru: 'Товары', hy: 'Ապրանքներ' },
						icon: 'product-workspace-svgrepo-com.svg',
						route: 'product',
					},
					{
						name: { en: 'Tags', ru: 'Теги', hy: 'Պիտակներ' },
						icon: 'tag-svgrepo-com.svg',
						route: 'tag',
					},
				],
				roles: ['admin', 'editor'],
			},
			management: {
				name: { en: 'Management', ru: 'Управление', hy: 'Կառավարում' },
				icon: 'folder-svgrepo-com.svg',
				collapse: [
					{
						name: { en: 'Users', ru: 'Пользователи', hy: 'Օգտատեր' },
						icon: 'users-svgrepo-com.svg',
						route: 'user',
					},
					{
						name: { en: 'Clients', ru: 'Клиенты', hy: 'Հաճախորդներ' },
						icon: 'clients-svgrepo-com.svg',
						route: 'client',
					},
				],
				roles: ['admin'],
			},
		},
		secondary: {
			kitchen: {
				name: {
					en: 'Kitchen Page',
					ru: 'Страница кухни',
					hy: 'Խոհանոցի էջ',
				},
				icon: 'kitchen-zone-svgrepo-com.svg',
				route: 'market/63218d786587b3c93447856d',
				roles: ['admin'],
			},
			store: {
				name: {
					en: 'Store Page',
					ru: 'Страница магазина',
					hy: 'Խանութի էջ',
				},
				icon: 'store-svgrepo-com.svg',
				route: 'market/66dc804cbe70e0bab98f9ca2',
				roles: ['admin'],
			},
			aboutUs: {
				name: { en: 'About Us', ru: 'О нас', hy: 'Մեր մասին' },
				icon: 'folder-svgrepo-com.svg',
				collapse: [
					{
						name: { en: 'Page', ru: 'Страница', hy: 'Էջ' },
						icon: 'page-svgrepo-com.svg',
						route: 'about-us',
					},
					{
						name: {
							en: 'Privileges',
							ru: 'Привилегии',
							hy: 'Արտոնություններ',
						},
						icon: 'privilege-svgrepo-com.svg',
						route: 'about-us-privileges',
					},
				],
				roles: ['admin'],
			},
			contacts: {
				name: { en: 'Contacts', ru: 'Контакты', hy: 'Կոնտակտներ' },
				icon: 'folder-svgrepo-com.svg',
				collapse: [
					{
						name: { en: 'Page', ru: 'Страница', hy: 'Էջ' },
						icon: 'page-svgrepo-com.svg',
						route: 'contact',
					},
					{
						name: { en: 'Parts', ru: 'Части', hy: 'Մասեր' },
						icon: 'tickets-svgrepo-com.svg',
						route: 'contact-part',
					},
					{
						name: { en: 'Types', ru: 'Типы', hy: 'Տեսակներ' },
						icon: 'status-svgrepo-com.svg',
						route: 'contact-type',
					},
					{
						name: { en: 'Social', ru: 'Соцыальные', hy: 'Սոցիալական' },
						icon: 'status-svgrepo-com.svg',
						route: 'social-link',
					},
				],
				roles: ['admin'],
			},
			corporate: {
				name: { en: 'Corporate', ru: 'Корпоративный', hy: 'Կորպորատիվ' },
				icon: 'agreement-deal-svgrepo-com.svg',
				route: 'corporate',
				roles: ['admin'],
			},
			delivery: {
				name: {
					en: 'Delivery and Payment',
					ru: 'Доставка и оплата',
					hy: 'Առաքում և վճարում',
				},
				icon: 'delivery-svgrepo-com.svg',
				route: 'delivery',
				roles: ['admin'],
			},
			fishCoin: {
				name: { en: 'Fish Coin', ru: 'Fish Coin', hy: 'Fish Coin' },
				icon: 'coin-svgrepo-com.svg',
				route: 'fishcoin',
				roles: ['admin'],
			},
			banner: {
				name: { en: 'Banner', ru: 'Баннер', hy: 'Բաններ' },
				route: 'banner',
				icon: 'gallery-svgrepo-com.svg',
				roles: ['admin'],
			},
			media: {
				name: { en: 'Media', ru: 'Медия', hy: 'Մեդիա' },
				route: 'media',
				icon: 'gallery-svgrepo-com.svg',
				roles: ['admin'],
			},
			privacy: {
				name: {
					en: 'Privacy policy',
					ru: 'Политика конфиденциальности',
					hy: 'Գաղտնիության քաղաքականություն',
				},
				icon: 'internet-svgrepo-com.svg',
				route: 'privacy-policy',
				roles: ['admin'],
			},
			terms: {
				name: {
					en: 'Terms of service',
					ru: 'Условия обслуживания',
					hy: 'Ծառայությունների մատուցման պայմաններ',
				},
				icon: 'software-svgrepo-com.svg',
				route: 'terms-of-service',
				roles: ['admin'],
			},
		},
	},
	developedBy: {
		logoUrl: 'https://api.deepmindsystems.com/logo.svg',
		name: 'Deepmindsystems',
		text: {
			en: `Designed and Developed by `,
			hy: `Спроектировано и разработано `,
			ru: `Նախագծված և մշակված է `,
		},
	},
	statuses: [
		{
			_id: '1',
			name: { en: 'Ordered', ru: 'Заказан', hy: 'Պատվիրվել է' },
		},
		{
			_id: '2',
			name: { en: 'In Progress', ru: 'В ходе выполнения', hy: 'Հավաքվում է' },
		},
		{
			_id: '3',
			name: { en: 'Serving', ru: 'Обслуживание', hy: 'Մատուցում' },
		},
		{
			_id: '4',
			name: { en: 'End', ru: 'Конец', hy: 'Ավարտ' },
		},
		{
			_id: '5',
			name: { en: 'Failed', ru: 'Не удалось', hy: 'Ձախողվեց' },
		},
	],
	ARMENIAN_CITIES: [
		{
			name: {
				en: 'Yerevan',
				ru: 'Ереван',
				hy: 'Երևան',
			},
			_id: 'yerevan',
			price: 1000,
		},
		{
			name: {
				en: 'Gyumri',
				ru: 'Гюмри',
				hy: 'Գյումրի',
			},
			_id: 'gyumri',
			price: 12000,
		},
		{
			name: {
				en: 'Ejmiacin',
				ru: 'Эджмиацин',
				hy: 'Էջմիածին',
			},
			_id: 'ejmiacin',
			price: 5000,
		},
		{
			name: {
				en: 'Dzoraghbyur',
				ru: 'Дзорахбюр',
				hy: 'Ձորաղբյուր',
			},
			_id: 'dzoraghbyur',
			price: 1500,
		},
		{
			name: {
				en: 'Abovyan',
				ru: 'Абовян',
				hy: 'Աբովյան',
			},
			_id: 'abovyan',
			price: 1700,
		},
		{
			name: {
				en: 'Ararat',
				ru: 'Арарат',
				hy: 'Արարատ',
			},
			_id: 'ararat',
			price: 5800,
		},
		{
			name: {
				en: 'Geghanist',
				ru: 'Геханист',
				hy: 'Գեղանիստ',
			},
			_id: 'geghanist',
			price: 1500,
		},
	],
};

export default config;
