import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useUser } from '../../helpers/userContext';
import { useLang } from '../../helpers/language';

export default function Client() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.client;
  const { lang } = useLang();

  const [index, setIndex] = useState('');
  const [firstNameEn, setFirstNameEn] = useState('');
  const [firstNameRu, setFirstNameRu] = useState('');
  const [firstNameHy, setFirstNameHy] = useState('');
  const [lastNameEn, setLastNameEn] = useState('');
  const [lastNameRu, setLastNameRu] = useState('');
  const [lastNameHy, setLastNameHy] = useState('');
  const [username, setUsername] = useState('');
  let [usernameValidator, setUsernameValidator] = useState('');
  let [usernamePhoneCode, setUsernamePhoneCode] = useState('+374');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const { user } = useUser();
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  // const [phone, setPhone] = useState('');
  const [telegram, setTelegram] = useState('');
  const [viber, setViber] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [fishcoinCount, setFishCoinCount] = useState();
  const [fishcoinBurnTime, setFishCoinBurnTime] = useState(new Date());
  const [cart, setCart] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const [clientGroup, setClientGroup] = useState('');
  const [image, setImage] = useState(undefined);

  const roles = [
    {
      _id: 'admin',
      name: { en: 'Admin', ru: 'Adminstrator', hy: 'Adminchik' },
    },
    {
      _id: 'manager',
      name: { en: 'Manager', ru: 'Managerru', hy: 'Managerhy' },
    },
    {
      _id: 'editor',
      name: { en: 'Editor', ru: 'Editorru', hy: 'Managerhy' },
    },
  ];
  const genders = [
    {
      _id: 'male',
      name: { en: 'Male', ru: 'Мужской', hy: 'Արական' },
    },
    {
      _id: 'female',
      name: { en: 'Female', ru: 'Женский', hy: 'Իգական' },
    },
  ];

  const modelSendToServer = {
    index,
    firstName: {
      en: firstNameEn,
      ru: firstNameRu,
      hy: firstNameHy,
    },
    lastName: {
      en: lastNameEn,
      ru: lastNameRu,
      hy: lastNameHy,
    },
    username: usernamePhoneCode + username,
    password,
    dateOfBirth,
    gender,
    company,
    address,
    // phone,
    telegram,
    viber,
    whatsapp,
    email,
    role,
    fishcoin: {
      count: fishcoinCount,
      burnTime: fishcoinBurnTime,
    },
    cart,
    favorites,
    client_group_id: clientGroup ? clientGroup : undefined,
  };
  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setIndex(currentItem[0]?.index);
        setFirstNameEn(currentItem[0]?.firstName?.en);
        setFirstNameRu(currentItem[0]?.firstName?.ru);
        setFirstNameHy(currentItem[0]?.firstName?.hy);
        setLastNameEn(currentItem[0]?.lastName?.en);
        setLastNameRu(currentItem[0]?.lastName?.ru);
        setLastNameHy(currentItem[0]?.lastName?.hy);

        setUsername(
          currentItem[0]?.username
            ? currentItem[0]?.username.includes('+374')
              ? currentItem[0]?.username.replace('+374', '')
              : currentItem[0]?.username.includes('+7')
              ? currentItem[0]?.username.replace('+7', '')
              : currentItem[0]?.username.includes('+1')
              ? currentItem[0]?.username.replace('+1', '')
              : ''
            : ''
        );
        setUsernamePhoneCode(
          currentItem[0]?.username
            ? currentItem[0]?.username.includes('+374')
              ? '+374'
              : currentItem[0]?.username.includes('+7')
              ? '+7'
              : currentItem[0]?.username.includes('+1')
              ? '+1'
              : ''
            : ''
        );

        setPassword(currentItem[0]?.password);
        currentItem[0]?.dateOfBirth &&
          setDateOfBirth(currentItem[0]?.dateOfBirth);
        setCompany(currentItem[0]?.company);
        setAddress(currentItem[0]?.address);
        // setPhone(currentItem[0]?.phone);
        setTelegram(currentItem[0]?.telegram);
        setViber(currentItem[0]?.viber);
        setWhatsapp(currentItem[0]?.whatsapp);
        setEmail(currentItem[0]?.email);
        setFishCoinCount(currentItem[0]?.fishcoin?.count);
        currentItem[0]?.fishcoin?.burnTime &&
          setFishCoinBurnTime(new Date(currentItem[0]?.fishcoin?.burnTime));
        setFavorites(currentItem[0]?.favorite_ids);
        setCart(currentItem[0]?.cart);
        setGender(currentItem[0]?.gender);
        setClientGroup(currentItem[0]?.client_group_id);

        let images = await getUploads(id, 'image');
        setImage(images && images[0]);

        roles.forEach((item) => {
          if (item._id === currentItem[0]?.role) {
            setRole(item._id);
          }
        });
        config.CLIENT_GROUPS &&
          config.CLIENT_GROUPS.forEach((item) => {
            if (item._id === currentItem[0]?.client_group_id) {
              setClientGroup(item._id);
            }
          });
        genders.forEach((item) => {
          if (item._id === currentItem[0]?.gender) {
            setGender(item._id);
          }
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setIndex,
            type: 'number',
            usageType: 'number',
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
          },
          {
            value: firstNameHy,
            setter: setFirstNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.firstName[lang],
            placeholder: config.translate.firstName[lang],
            lang: 'hy',
          },
          {
            value: lastNameHy,
            setter: setLastNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.lastName[lang],
            placeholder: config.translate.lastName[lang],
            lang: 'hy',
          },
          {
            value: firstNameRu,
            setter: setFirstNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.firstName[lang],
            placeholder: config.translate.firstName[lang],
            lang: 'ru',
          },
          {
            value: lastNameRu,
            setter: setLastNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.lastName[lang],
            placeholder: config.translate.lastName[lang],
            lang: 'ru',
          },
          {
            value: firstNameEn,
            setter: setFirstNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.firstName[lang],
            placeholder: config.translate.firstName[lang],
            lang: 'en',
          },
          {
            value: lastNameEn,
            setter: setLastNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.lastName[lang],
            placeholder: config.translate.lastName[lang],
            lang: 'en',
          },
          {
            value: username,
            setter: setUsername,
            type: 'tel',
            setValidator: setUsernameValidator,
            validator: 'username',
            usageType: 'phone-number',
            label: config.translate.phone[lang],
            placeholder: config.translate.phone[lang],
            phoneCodeState: usernamePhoneCode,
            setPhoneCodeState: setUsernamePhoneCode,
          },
          {
            value: password,
            setter: setPassword,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.password[lang],
            placeholder: config.translate.password[lang],
          },
          {
            value: dateOfBirth,
            setter: setDateOfBirth,
            type: 'date',
            usageType: 'datePicker',
            label: config.translate.dateOfBirth[lang],
            placeholder: 'select birthday',
          },
          {
            value: company,
            setter: setCompany,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.company[lang],
            placeholder: config.translate.company[lang],
          },
          {
            value: address,
            setter: setAddress,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
          },
          // {
          //   value: phone,
          //   setter: setPhone,
          //   type: 'text',
          //   usageType: 'textfield',
          //   label: config.translate.phone[lang],
          //   placeholder: config.translate.phone[lang],
          //   lang: 'hy',
          // },

          {
            value: viber,
            setter: setViber,
            radioValue: [
              { value: true, name: 'Viber', forward: true },
              { value: false, name: 'Off', forward: true },
            ],
            type: 'radio',
            usageType: 'radio',
            label: 'Viber',
          },
          {
            value: whatsapp,
            setter: setWhatsapp,
            radioValue: [
              { value: true, name: 'Whatsapp', forward: true },
              { value: false, name: 'Off', forward: true },
            ],
            type: 'radio',
            usageType: 'radio',
            label: 'Whatsapp',
          },
          {
            value: telegram,
            setter: setTelegram,
            radioValue: [
              { value: true, name: 'Telegram', forward: true },
              { value: false, name: 'Off', forward: true },
            ],
            type: 'radio',
            usageType: 'radio',
            label: 'Telegram',
          },
          {
            value: email,
            setter: setEmail,
            type: 'text',
            usageType: 'textfield',
            label: 'Email',
            placeholder: 'Email',
          },
          {
            value: fishcoinCount,
            setter: setFishCoinCount,
            type: 'number',
            usageType: 'number',
            label: 'Fishcoin',
          },
          {
            value: fishcoinBurnTime,
            setter: setFishCoinBurnTime,
            type: 'date',
            usageType: 'datePicker',
            label: 'Fishcoin END',
          },
          {
            value: gender,
            setter: setGender,
            optionListValue: genders,
            type: 'text',
            usageType: 'optionlist',
            label: config.translate.gender[lang],
          },
          {
            value: clientGroup,
            setter: setClientGroup,
            optionListValue: config.CLIENT_GROUPS,
            type: 'optionlist',
            usageType: 'optionlist',
            label: config.translate.menu.clientGroup[lang],
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: image,
            filesUploadLimit: 1,
            acceptedFiles: ['image/*'],
            adminMode: user.role !== 'editor',
          },
        ]}
      />
    );
}
