import React from 'react';

export function InfoBox({ header, value, classname, icon }) {
  return (
    <div className='col-12 col-sm-6 col-md-3'>
      <div className={`stats-cart info-box`}>
        <span className={`info-box-icon ${classname} elevation-1`}>
          <i className={icon}></i>
        </span>
        <div className='info-box-content'>
          <span className='info-box-text'>{header}</span>
          <span className='info-box-number'>{value}</span>
        </div>
      </div>
    </div>
  );
}

export function SmallBox({ header, value, classname, img }) {
  return (
    <div className='col-lg-3 col-6'>
      <div className={`stats-cart small-box ${classname}`}>
        <div className='icon text-center pt-4'>
          <img src={`${img}`} alt='ico-im' />
        </div>
        <div className='inner'>
          <p className='text-center'>{header}</p>
          <h3 className='text-center'>{value}</h3>
        </div>
      </div>
    </div>
  );
}
