import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Navigation from './navigation';
import { useAuth } from '../../helpers/auth';
import config from '../../config';
import { useUser } from '../../helpers/userContext';

export default function Sidebar() {
  const { logout } = useAuth();
  const location = useLocation();
  const { user } = useUser();
  const lang = 'hy';

  const toggleDrawer = (e) => {
    let elem = e.currentTarget.parentNode;
    if (elem.classList.contains('menu-open'))
      elem.classList.remove('menu-open');
    else elem.classList.add('menu-open');
  };
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  return (
    <aside className='main-sidebar sidebar-dark-primary elevation-4'>
      <span className='brand-link'>
        <Link
          to={'/'}
          className='brand-text font-weight-light'
        >
          <img
            src={config.brand.logo}
            alt='Website Logo'
            className='brand-image'
            style={{ opacity: 0.8 }}
          />
          {/* {config.brand.name} */}
        </Link>
      </span>

      <div className='sidebar'>
        <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
          <div className='user-image'>
            {user?.uploads?.path ?
              <img
                src={`${config.api.API_URL}/${user?.uploads?.path}`}
                className='img-circle elevation-2'
                alt='User'
              />
              :
              <img
                src="/user-default.png"
                className='img-circle elevation-2'
                alt='User'
              />
            }
          </div>
          <div className='info'>
            <span className='d-block'>
              {user ? user.firstName?.hy + ' ' + user.lastName?.hy : 'Username'}
            </span>
          </div>
          <div className='info'>
            <span onClick={logout} className='d-block'>
              <i className='fas fa-sign-out-alt'></i>
            </span>
          </div>
        </div>

        <nav className='mt-2'>
          <ul
            className='nav nav-pills nav-sidebar nav-child-indent'
            data-widget='treeview'
            role='menu'
            data-accordion='false'
          >
            {/* <li className="nav-header">{config.static}</li> */}

            {Object.values(config.menu.primary).map((item, index) => {
              if (item.roles) {
                let result;
                item.roles.forEach((role) => {
                  if (role === user.role) {
                    result = (
                      <li
                        className={`nav-item ${item.collapse && 'has-treeview'
                          }`}
                        key={index}>
                        {item.collapse ? (
                          <>
                            <span className='nav-link' onClick={toggleDrawer}>
                              <img
                                className='navigation-icon'
                                src={`/assets/${item.icon}`}
                                alt='menu-i'
                              />
                              <p>
                                {item.name[lang].charAt(0).toUpperCase() +
                                  item.name[lang].slice(1)}
                                <i className='right fas fa-angle-left'></i>
                              </p>
                            </span>
                            <ul className='nav nav-treeview'>
                              {item.collapse.map((innerItem, innerIndex) => {
                                return (
                                  <li className='nav-item' key={innerIndex}>
                                    <Link
                                      className={
                                        activeRoute(`/${innerItem.route}/`)
                                          ? 'nav-link active'
                                          : 'nav-link'
                                      }
                                      to={`/${innerItem.route}/`}>
                                      {/* <i className={`nav-icon ${innerItem.icon}`}></i> */}
                                      <img
                                        className='navigation-icon'
                                        src={`/assets/${innerItem.icon}`}
                                        alt='navgation-icon'
                                      />
                                      <p>
                                        {innerItem.name[lang]
                                          .charAt(0)
                                          .toUpperCase() +
                                          innerItem.name[lang].slice(1)}
                                      </p>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        ) : (
                          <Link
                            to={`/${item.route}/`}
                            className={
                              activeRoute(item.route)
                                ? 'nav-link active'
                                : 'nav-link'
                            }>
                            {/* <i className={`nav-icon ${item.icon}`}></i> */}
                            <img
                              className='navigation-icon'
                              src={`/assets/${item.icon}`}
                              alt='navgation-icon'
                            />
                            <p>
                              {item.name[lang].charAt(0).toUpperCase() +
                                item.name[lang].slice(1)}
                            </p>
                          </Link>
                        )}
                      </li>
                    );
                  } else {
                    return null;
                  }
                });
                return result;
              } else {
                return null;
              }
            })}

            {Object.values(config.menu.secondary) && (
              <li className='nav-header'>{config.translate.more[lang]}</li>
            )}
            {Object.values(config.menu.secondary) &&
              Object.values(config.menu.secondary).map((item, index) => {
                if (item.roles) {
                  let result;
                  item.roles.forEach((role) => {
                    if (role === user.role) {
                      result = (
                        <li
                          className={`nav-item ${item.collapse && 'has-treeview'
                            }`}
                          key={index}>
                          {item.collapse ? (
                            <>
                              <span className='nav-link' onClick={toggleDrawer}>
                                <img
                                  className='navigation-icon'
                                  src={`/assets/${item.icon}`}
                                  alt='menu-i'
                                />
                                <p>
                                  {item.name[lang].charAt(0).toUpperCase() +
                                    item.name[lang].slice(1)}
                                  <i className='right fas fa-angle-left'></i>
                                </p>
                              </span>
                              <ul className='nav nav-treeview'>
                                {item.collapse.map((innerItem, innerIndex) => {
                                  return (
                                    <li className='nav-item' key={innerIndex}>
                                      <Link
                                        className={
                                          activeRoute(`/${innerItem.route}/`)
                                            ? 'nav-link active'
                                            : 'nav-link'
                                        }
                                        to={`/${innerItem.route}/`}>
                                        {/* <i className={`nav-icon ${innerItem.icon}`}></i> */}
                                        <img
                                          className='navigation-icon'
                                          src={`/assets/${innerItem.icon}`}
                                          alt='navgation-icon'
                                        />
                                        <p>
                                          {innerItem.name[lang]
                                            .charAt(0)
                                            .toUpperCase() +
                                            innerItem.name[lang].slice(1)}
                                        </p>
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <Link
                              className={
                                activeRoute(`/${item.route}/`)
                                  ? 'nav-link active'
                                  : 'nav-link'
                              }
                              to={`/${item.route}/`}>
                              {/* <i className={`nav-icon ${item.icon}`}></i> */}
                              <img
                                className='navigation-icon'
                                src={`/assets/${item.icon}`}
                                alt='navgation-icon'
                              />
                              <p>
                                {item.name[lang].charAt(0).toUpperCase() +
                                  item.name[lang].slice(1)}
                              </p>
                            </Link>
                          )}
                        </li>
                      );
                    } else {
                      return null;
                    }
                  });
                  return result;
                } else {
                  return null;
                }
              })}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
