import React, { useState, useEffect, Suspense } from 'react';
// import './adminlte.css';
import './scss/adminlte.scss';

// Helpers
import history from './helpers/history';
import { cookies, setCookies } from './helpers/cookies';
import { isLoggedIn } from './helpers/auth';
import { Language } from './helpers/language';
import { UserContext } from './helpers/userContext';
import { ConnectedUserContext } from './helpers/connectedUserContext';
import API from './helpers/api';
import config from './config';
import Loading from './components/modules/loading.jsx';
import { io } from 'socket.io-client';

// global components
import Login from './components/login';
import Main from './components/main/main';

//Routing
import { Router, Route, Switch } from 'react-router-dom';

// Components
import Home from './components/home';
import Page404 from './components/Page404';
import User from './components/user/user';
import Users from './components/user/users';

import Orders from './components/order/orders';
import Order from './components/order/order';

import Categories from './components/category/categories';
import Category from './components/category/category';

import Products from './components/product/products';
import Product from './components/product/product';

import Tags from './components/tag/tags';
import Tag from './components/tag/tag';

import Clients from './components/client/clients';
import Client from './components/client/client';

import Market from './components/market/market';

import AboutUsPage from './components/about-us/aboutUsPage';
import AboutUsPrivileges from './components/about-us/aboutUsPrivileges';
import AboutUsPrivilege from './components/about-us/aboutUsPrivilege';

import ContactPage from './components/contact/contactPage';
import ContactPart from './components/contact/contactPart';
import ContactParts from './components/contact/contactParts';
import ContactType from './components/contact/contactType';
import ContactTypes from './components/contact/contactTypes';
import SocialLink from './components/contact/socialLink';
import SocialLinks from './components/contact/socialLinks';

import Corporate from './components/corporate/corporate';

import DeliveryPayment from './components/delivery-payment/deliveryPayment';

import Fishcoin from './components/fishcoin/fishcoin';
import PrivacyPolicy from './components/privacy/privacy';
import TermsOfService from './components/terms/terms';

import Banners from './components/banner/banners';
import Banner from './components/banner/banner';

import Media from './components/media/media';

function RouterComponent({ user }) {
  // window.scrollTo(0, 0);
  if (user) {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/home' component={Home} />
          {user.role === 'admin' && (
            <>
              <Route exact path='/order' component={Orders} />
              <Route path='/order/:id' component={Order} />

              <Route exact path='/category' component={Categories} />
              <Route path='/category/:id' component={Category} />

              <Route exact path='/product' component={Products} />
              <Route path='/product/:id' component={Product} />

              <Route exact path='/tag' component={Tags} />
              <Route path='/tag/:id' component={Tag} />

              <Route exact path='/client' component={Clients} />
              <Route path='/client/:id' component={Client} />

              <Route exact path='/market/:id' component={Market} />
              <Route exact path='/social-link/' component={SocialLinks} />
              <Route exact path='/social-link/:id' component={SocialLink} />

              <Route path='/about-us' component={AboutUsPage} />
              <Route
                exact
                path='/about-us-privileges'
                component={AboutUsPrivileges}
              />
              <Route
                path='/about-us-privileges/:id'
                component={AboutUsPrivilege}
              />

              <Route path='/contact' component={ContactPage} />
              <Route exact path='/contact-part' component={ContactParts} />
              <Route path='/contact-part/:id' component={ContactPart} />
              <Route exact path='/contact-type' component={ContactTypes} />
              <Route path='/contact-type/:id' component={ContactType} />

              <Route exact path='/corporate' component={Corporate} />
              <Route exact path='/delivery' component={DeliveryPayment} />
              <Route exact path='/fishcoin' component={Fishcoin} />
              <Route exact path='/privacy-policy' component={PrivacyPolicy} />
              <Route
                exact
                path='/terms-of-service'
                component={TermsOfService}
              />
              <Route exact path='/banner' component={Banners} />
              <Route exact path='/banner/:id' component={Banner} />
              <Route exact path='/media' component={Media} />
              <Route exact path='/user' component={Users} />
              <Route path='/user/:id' component={User} />
            </>
          )}
          {user.role === 'manager' && (
            <>
              <Route exact path='/order' component={Orders} />
              <Route path='/order/:id' component={Order} />
            </>
          )}
          {user.role === 'editor' && (
            <>
              <Route exact path='/category' component={Categories} />
              <Route path='/category/:id' component={Category} />
              <Route exact path='/product' component={Products} />
              <Route path='/product/:id' component={Product} />
            </>
          )}
          <Route path='*' component={Page404} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense>
        <Route path='*' component={Page404} />
      </Suspense>
    );
  }
}

function App() {
  let [loggedIn, setLoggedIn] = useState(Boolean);
  let [lang, setLang] = useState('hy');
  let [user, setUser] = useState(Boolean);
  // eslint-disable-next-line
  let [notifications, setnotifications] = useState([]);
  let [socket, setSocket] = useState({});

  let DissconnectSocket = () => {
    socket.disconnect();
    // setSocketOrders([...socketOrders]);
  };

  function ConnectSocket(userFromServer) {
    const socket = io(`${config.api.API_URL}`, {
      transports: ['websocket'],
    });
    setSocket(socket);
    socket.on('connect_error', () => {
      setTimeout(() => {
        socket.connect();
      }, 1000);
    });

    // listen for notifications only for admins
    socket.on('ordersData', (ordersData) => {
      // setnotifications(ordersData.reverse());
      setnotifications(ordersData);
    });
    socket.on('newOrder', (ordersSocket) => {
      // setnotifications(ordersSocket.reverse());
      setnotifications(ordersSocket);
    });
  }

  async function logout() {
    await setCookies('');
    DissconnectSocket();
    await setLoggedIn(false);
  }

  function login(username, password) {
    if (username && password) {
      API.post(config.authentication.login, {
        username,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setLoggedIn(true);
            setUser(res.data.user);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      console.log(400);
      setLoggedIn(false);
      console.log('There is no user with provided username & password');
    }
  }

  function checkToken() {
    let token = cookies.get(config.authentication.tokenAddress);
    if (token) {
      API.post(config.authentication.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setUser(res.data.user);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(`Please don't edit cookies manually`);
          cookies.remove('authorization');
        });
    } else {
      setLoggedIn(false);
      console.log(
        'You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running'
      );
    }
  }

  useEffect(() => {
    (async () => {
      ConnectSocket();
      // handle after refresh correct notifications format whis is Array
      if (
        notifications.constructor.name === String &&
        notifications !== ' ' &&
        notifications !== ''
      ) {
        setnotifications(notifications.split(','));
      }
      await checkToken();
      await Notification.requestPermission();
    })();
    // eslint-disable-next-line
  }, []);

  if (loggedIn && user) {
    return (
      <Language.Provider value={{ lang, setLang }}>
        <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
          <UserContext.Provider value={{ user }}>
            <ConnectedUserContext.Provider
              value={{
                socket,
                notifications,
                setnotifications,
              }}>
              <Router history={history}>
                <Main component={RouterComponent} user={user} />
              </Router>
            </ConnectedUserContext.Provider>
          </UserContext.Provider>
        </isLoggedIn.Provider>
      </Language.Provider>
    );
  } else {
    return (
      <Language.Provider value={{ lang, setLang }}>
        <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
          <Router history={history}>
            <Route component={Login} />
          </Router>
        </isLoggedIn.Provider>
      </Language.Provider>
    );
  }
}
export default App;
