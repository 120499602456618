import React from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import { useLang } from '../../helpers/language';

export default function Clients() {
  const { lang } = useLang();
  let customSort = 'index';
  const columns = [
    {
      dataField: 'index',
      text: config.translate.index[lang],
      filter: true,
      sort: true,
      headerStyle: (column, colIndex) => {
        return {
          width: '110px',
        };
      },
      align: 'center',
    },
    {
      dataField: 'firstName.hy',
      text: config.translate.firstName[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: 'lastName.hy',
      text: config.translate.lastName[lang],
      filter: true,
      sort: true,
    },
    {
      dataField: 'dateOfBirth',
      text: config.translate.dateOfBirth[lang],
      filter: true,
      sort: true,
      formatDate: true,
      align: 'left',
    },
    {
      dataField: 'role',
      text: config.translate.role[lang],
      filter: true,
      sort: true,
      formatRole: true,
      headerStyle: (column, colIndex) => {
        return {
          width: '136px',
        };
      },
    },
  ];
  // const rowStyle = (row, rowIndex) => {
  //   let years = moment().diff(row.dateOfBrth, "years", false);
  //   const style = {};
  //   if (years) {
  //     style.backgroundColor = "#c8e";
  //   } else {
  //     style.backgroundColor = "#00BFFF";
  //   }
  //   return style;
  // };
  return (
    <ListPage
      customSort={customSort}
      columns={columns}
      route={config.api.client}
      defaultSortedDataField={'index'}
      // rowStyle={rowStyle}
    />
  );
}
