import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useUser } from '../../helpers/userContext';
import { useLang } from '../../helpers/language';

export default function ContactPage() {
  const [isBusy, setBusy] = useState(true);
  const route = config.api.contactPage;
  const { user } = useUser();
  const { lang } = useLang();

  const [descEn, setDescEn] = useState('');
  const [descRu, setDescRu] = useState('');
  const [descHy, setDescHy] = useState('');

  const [image, setImage] = useState('');
  const [id, setId] = useState('');

  const modelSendToServer = {
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    },
  };

  useEffect(() => {
    (async () => {
      let currentItem = await API.get(route);
      setId(currentItem[0]?._id);
      setDescEn(currentItem[0]?.desc?.en);
      setDescRu(currentItem[0]?.desc?.ru);
      setDescHy(currentItem[0]?.desc?.hy);

      let images = await getUploads(id, 'image');
      setImage(images && images[0]);
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: descHy,
            setter: setDescHy,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'hy',
            fullWidth: true,
          },
          {
            value: descRu,
            setter: setDescRu,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'ru',
            fullWidth: true,
          },
          {
            value: descEn,
            setter: setDescEn,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'en',
            fullWidth: true,
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: image,
            filesUploadLimit: 1,
            adminMode: user.role !== 'editor',
            acceptedFiles: '.jpg, .jpeg, .png',
            thumbSizeWidth: 270,
            responsiveImages: true,
          },
        ]}
      />
    );
}
