import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ChromePicker } from 'react-color';
import Swal from 'sweetalert2';
import config from '../../config';
import { useLang } from '../../helpers/language';
import validator from 'validator';

let timeout = null;
const Input = (props?) => {
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [bloko, setbloko] = useState('');
  const { lang } = useLang();
  const [invalid, setInvalid] = useState(false);
  let [inputStatus, setInputStatus] = useState(undefined);

  useEffect(() => {
    if (props?.required && (!props.value || props.value === '')) {
      props.setValid && props.setValid(true);
      setInvalid(true);
    }
  }, []);

  const handleChange = async (e) => {
    let value = e.target?.value;
    if (props?.required && (!value || value === '')) {
      props.setValid && props.setValid(true);
      setInvalid(true);
    } else {
      setInvalid(false);
      props.setValid && props.setValid(false);
    }
    if (props?.trim) value = value.trim();
    if (props?.tolowercase) value = value.toLowerCase();
    value = value.trimStart();
    if (props?.servercall && props?.api && props.apiurl) {
      // Init a timeout variable to be used below
      clearTimeout(timeout);
      timeout = setTimeout(async function () {
        if (props.type === 'email') {
          let res = await props.api.get(props.apiurl, {
            email: value,
          });
          if (res[0]) {
            Swal.fire(props.config.swal.emailIsBusy[props.language]);
            props.setter('');
            return;
          }
        } else {
          let res = await props.api.get(props.apiurl, {
            username: value,
          });
          if (res[0]) {
            Swal.fire(props.config.swal.usernameIsBusy[props.language]);
            props.setter('');
            return;
          }
        }
      }, 1000);
    }

    if (value.length > 0 && value !== '' && value) {
      if (props.validator === 'email') {
        let result = validator.isEmail(value);
        setInputStatus(result);
        props.setValidator && props.setValidator(result);
      }
      if (props.validator === 'password') {
        let result = validator.isLength(value, { min: 8, max: 25 });
        props.setValidator && props.setValidator(result);
        setInputStatus(result);
      }
      if (props.validator === 'username') {
        let result = validator.isLength(value, { min: 8, max: 15 });
        props.setValidator && props.setValidator(result);
        setInputStatus(result);
      }
      if (props.validator === 'certificate') {
        let result = validator.isLength(value, { min: 5, max: 5 });
        props.setValidator && props.setValidator(result);
        setInputStatus(result);
      }
      if (props.validator === 'social') {
        let result = validator.isURL(value);
        props.setValidator && props.setValidator(result);
        setInputStatus(result);
      }
      if (props.validator === 'phone') {
        let result = validator.isMobilePhone(value);
        props.setValidator && props.setValidator(result);
        setInputStatus(result);
      }
      if (props.validator === 'notanemptytext') {
        let result = validator.isLength(value, { min: 1, max: 25 });
        props.setValidator && props.setValidator(result);
        setInputStatus(result);
      }
    } else if (props.validator) {
      setInputStatus(false);
      props.setValidator && props.setValidator(false);
    }
    props.setter(value);
  };

  const handlePriceChange = (e) => {
    props.pricesetter(e?.target?.value);
  };

  const handleTimeIntervalChange = (e) => {
    props.settimeInterval(e?.target?.value);
  };

  const handleTextFieldChange = (data) => {
    props.setter(data);
  };

  const handleColorChange = (color) => {
    props.setter(color.hex);
  };

  const toggleShowColorPicker = () => {
    setshowColorPicker(!showColorPicker);
  };
  const editorRef = useRef(null);

  const handlePhoneNumberChange = (e) => {
    let value = props.value;
    if (value.includes('+374')) value = value.replace('+374', '');
    else if (value.includes('+7')) value = value.replace('+7', '');
    else if (value.includes('+1')) value = value.replace('+1', '');
    props.setter(value);
    props.setPhoneCodeState(e.target.value);
  };

  if (props?.usageType === 'textfield') {
    return (
      <div
        key={props?.id}
        className={`form-element ${props.fullWidth && 'form-element-wide'}`}
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        {props?.disabled ? (
          <p>{props?.value}</p>
        ) : (
          <input
            value={props?.value || ''}
            onChange={handleChange}
            type={props?.type}
            className={`form-control ${invalid ? 'is-invalid' : ''}`}
            id={props?.label}
            placeholder={props?.placeholder}
          />
        )}
      </div>
    );
  } else if (props?.usageType === 'percent') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <div className='input-group'>
          <input
            value={props?.value || ''}
            onChange={handleChange}
            type={props?.type}
            className='form-control'
            id={props?.label}
            placeholder={props?.placeholder}
          />
          <span className='input-group-append'>
            <button type='button' className='btn btn-info btn-flat'>
              %
            </button>
          </span>
        </div>
      </div>
    );
  } else if (props?.usageType === 'textarea') {
    return (
      <div
        key={props?.id}
        className={`form-element ${props.fullWidth && 'form-element-wide'}`}
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <Editor
	        apiKey="xixrs18qm4ct7982r7557dk24sswvnzf5967j7ppgbkp2x99"
          id={(Math.random() * 100).toString()}
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
	          tinycomments_mode: 'embedded',
            height: '50vh',
            width: '100%',
            strict_loading_mode: true,
            statusbar: false,
            branding: false,
            placeholder: props?.placeholder,
            fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
            menubar: 'file edit insert view format table',
            plugins: [
              'charmap advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount code',
            ],
            formatpainter_blacklisted_formats:
              'link,address,removeformat,formatpainter_removeformat',
            toolbar:
              // eslint-disable-next-line
              'charmap | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
          }}
          value={props?.value}
          onEditorChange={handleTextFieldChange}
        />
      </div>
    );
  } else if (props?.usageType === 'optionlist') {
    if (props?.optionListValue && Array.isArray(props?.optionListValue)) {
      return (
        <div
          key={props?.id}
          className='form-group form-element'
          style={{ width: props.width }}>
          {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
          <select onChange={handleChange} className='form-control'>
            {/* <option selected disabled>{config.translate.selectSomething[lang]}</option> */}
            {React.Children.toArray(
              props?.optionListValue?.map((opt) => {
                if (props?.value || props?.value?.length > 0) {
                  if (opt._id === props?.value) {
                    return (
                      <option selected value={opt._id}>
                        {opt[props?.field]
                          ? opt[props?.field]
                          : opt.name?.[lang]
                            ? opt.name?.[lang]
                            : 'none'}
                      </option>
                    );
                  } else {
                    return (
                      <>
                        <option value={opt._id}>
                          {opt[props?.field]
                            ? opt[props?.field]
                            : opt.name?.[lang]
                              ? opt.name?.[lang]
                              : 'none'}
                        </option>
                      </>
                    );
                  }
                } else {
                  return (
                    <>
                      <option value='none' selected disabled hidden>
                        {config.translate.selectSomething[lang]}
                      </option>
                      <option value={opt._id}>
                        {opt[props?.field]
                          ? opt[props?.field]
                          : opt.name?.[lang]
                            ? opt.name?.[lang]
                            : 'none'}
                      </option>
                    </>
                  );
                }
              })
            )}
          </select>
        </div>
      );
    }
    return null;
  } else if (props?.usageType === 'optionlist-simple') {
    return (
      <div
        key={props?.id}
        className='form-group form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <select onChange={handleChange} className='form-control'>
          {React.Children.toArray(
            props?.optionListValue?.map((opt) => {
              if (props?.value) {
                if (opt.name[props?.lang] === props?.value) {
                  return (
                    <option selected value={opt.name[props?.lang]}>
                      {opt[props?.field]
                        ? opt[props?.field]
                        : opt.name?.[lang]
                          ? opt.name?.[lang]
                          : 'none'}
                    </option>
                  );
                } else {
                  return (
                    <>
                      <option value={opt.name[props?.lang]}>
                        {opt[props?.field]
                          ? opt[props?.field]
                          : opt.name?.[lang]
                            ? opt.name?.[lang]
                            : 'none'}
                      </option>
                    </>
                  );
                }
              } else {
                return (
                  <>
                    <option value='none' selected disabled hidden>
                      {config.translate.selectSomething[lang]}
                    </option>
                    <option value={opt.name[props?.lang]}>
                      {opt[props?.field]
                        ? opt[props?.field]
                        : opt.name?.[lang]
                          ? opt.name?.[lang]
                          : 'none'}
                    </option>
                  </>
                );
              }
            })
          )}
        </select>
      </div>
    );
  } else if (props?.usageType === 'datePicker') {
    let value = JSON.parse(JSON.stringify(props?.value ? props?.value : '{}'));
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        {props?.disabled ? (
          <p>{moment(value).format('HH:mm:ss | DD/MM/YYYY')}</p>
        ) : (
          <input
            value={value?.slice(0, 10) || ''}
            onChange={handleChange}
            type={props?.type}
            className='Input-text form-control'
            id={props?.label}
            required={props?.required}
            // placeholder="YYYY"
            pattern={props?.pattern}
          />
        )}
      </div>
    );
  } else if (props?.usageType === 'number') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <input
          value={props?.value || ''}
          onChange={handleChange}
          type={props?.type}
          className='form-control'
          id={props?.label}
          placeholder={props?.placeholder}
          min={props.min}
        />
      </div>
    );
  } else if (props?.usageType === 'boolean') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        {props?.value ? (
          <p>
            <i className='fas fa-thumbs-up'></i>
          </p>
        ) : (
          <p>
            <i className='fas fa-exclamation-circle'></i>
          </p>
        )}
      </div>
    );
  } else if (props?.usageType === 'color-picker') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <div className='input-group'>
          <div className='input-group-btn'>
            <button
              className='btn btn-info btn-flat'
              onClick={toggleShowColorPicker}>
              Color Picker
            </button>
          </div>
          {/* <p >color test</p> */}
          <input
            value={props?.value || ''}
            onChange={handleChange}
            type={props?.type}
            className='form-control'
            id={props?.label}
            placeholder={props?.placeholder}
          />
          <div className='input-group-btn'>
            <button
              className='btn btn-flat'
              style={{ backgroundColor: props?.value }}>
              Color Example
            </button>
          </div>
        </div>
        {showColorPicker && <ChromePicker onChange={handleColorChange} />}
      </div>
    );
  } else if (props?.usageType === 'currency') {
    let placeholder = {
      value: {
        en: 'value',
        ru: 'цена',
        hy: 'արժեք',
      },
      duration: {
        en: 'duration',
        ru: 'продолжительность',
        hy: 'տևողություն',
      },
    };
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <div className='input-group'>
          <div className='input-group-btn'>
            {/* <button
              className="btn btn-info btn-flat"
              onClick={toggleShowColorPicker}
            >
              Color Picker
            </button> */}
            <select onChange={handleChange} className='btn btn-info btn-flat'>
              {React.Children.toArray(
                props?.optionListValue.map((opt) => {
                  if (props?.value) {
                    if (opt._id === props?.value) {
                      return (
                        <option selected value={opt._id}>
                          {opt.code}
                        </option>
                      );
                    } else {
                      return (
                        <>
                          <option value={opt._id}>{opt.code}</option>
                        </>
                      );
                    }
                  } else {
                    return (
                      <>
                        <option value='none' selected disabled hidden>
                          Выберите что-нибудь
                        </option>
                        <option value={opt._id}>
                          {opt[props?.field]
                            ? opt[props?.field]
                            : opt.code
                              ? opt.code
                              : 'none'}
                        </option>
                      </>
                    );
                  }
                })
              )}
            </select>
          </div>
          <input
            value={props?.price || ''}
            onChange={handlePriceChange}
            type='number'
            className='form-control'
            id={props?.label}
            placeholder={placeholder.value[props?.lang]}
          />
          <input
            value={props?.timeInterval || ''}
            onChange={handleTimeIntervalChange}
            type='number'
            className='form-control'
            id={props?.label}
            placeholder={placeholder.duration[props?.lang]}
          />
        </div>
        {showColorPicker && <ChromePicker onChange={handleColorChange} />}
      </div>
    );
  } else if (props?.usageType === 'timepast') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <div className='input-group'>
          <p>
            {moment
              .utc(
                moment(moment(), 'DD/MM/YYYY HH:mm:ss').diff(
                  moment(props?.value, 'DD/MM/YYYY HH:mm:ss')
                )
              )
              .format('HH:mm')}
          </p>
        </div>
      </div>
    );
  } else if (props?.usageType === 'timeleft') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        {props?.label && <label htmlFor={props?.label}>{props?.label}</label>}
        <div className='input-group'>
          <p>
            {moment(new Date(props?.value) - new Date()).format('DD:HH:mm')}
          </p>
        </div>
      </div>
    );
  } else if (props?.usageType === 'bloko') {
    return (
      <div
        key={props?.id}
        className={`Input ${props?.classNames}`}
        style={{ width: props.width }}>
        {/* <ul className="knowledges">
          {React.Children.toArray(
            isIterable(props?.value) &&
              props?.value?.map((skill, i) => {
                return (
                  <li>
                    <div className="bloko-container">
                      <div className="bloko-name">{skill}</div>
                      <img
                        key={i}
                        onClick={() => {
                          let cloneArr = [...props?.value];
                          cloneArr.splice(i, 1);
                          props?.setter(cloneArr);
                        }}
                        className="bloko-remove-icon"
                        src="/assets/delete.svg"
                        alt="skills delete icon"
                      />
                    </div>
                  </li>
                );
              })
          )}
        </ul> */}
        <input
          value={bloko || ''}
          onChange={(e) => {
            setbloko(e.target.value);
          }}
          type={props?.type}
          className='Input-text'
          id={props?.label}
          placeholder={props?.placeholder}
          pattern={props?.pattern}
        />
        <span
          className={`Input-check ${bloko ? '' : 'passive'}`}
          onClick={() => {
            let cloneArr = [...props?.value];
            cloneArr.push(bloko);
            setbloko('');
            props?.setter(cloneArr);
          }}>
          <img
            className='Input-check_img'
            src='/assets/check-solid.svg'
            alt='check svg'
          />
        </span>
      </div>
    );
  } else if (props?.usageType === 'toggle') {
    return (
      <div
        key={props?.id}
        className='form-element'
        style={{ width: props.width }}>
        <div className='form-check form-switch'>
          <input
            className='form-check-input'
            type='checkbox'
            id='flexSwitchCheckDefault'
            value={props?.value}
            onChange={handleChange}
          />
          {props?.label && (
            <label className='form-check-label' for='flexSwitchCheckDefault'>
              {props?.label}
            </label>
          )}
        </div>
      </div>
    );
  } else if (props?.usageType === 'radio') {
    return (
      <div
        key={props?.id}
        className='form-element form-element d-flex justify-content-center'
        style={{ width: props.width }}>
        <div
          className='btn-group btn-group-toggle d-flex align-items-center'
          data-toggle='buttons'>
          {React.Children.toArray(
            props?.radioValue?.map((opt, key) => {
              return (
                <label
                  key={key}
                  style={{ height: '44px' }}
                  className={`${props.value === opt.value ? 'active' : ''
                    }  btn btn-${opt.forward
                      ? opt.value === props.value
                        ? opt.value
                          ? 'success'
                          : 'danger'
                        : 'default'
                      : opt.value === props.value
                        ? opt.value
                          ? 'danger'
                          : 'success'
                        : 'default'
                    }`}>
                  <input
                    type='radio'
                    name='options'
                    id={props.value}
                    autoComplete='off'
                    onClick={() => {
                      props.setter(opt.value);
                    }}
                    checked={props.value}
                  />{' '}
                  {opt.name}
                </label>
              );
            })
          )}
        </div>
      </div>
    );
  } else if (props?.usageType === 'phone-number') {
    return (
      <div key={props.id} className={`form-element ${props.classNames}`}>
        {props.label && (
          <label htmlFor='input' className='Input-label'>
            {props.label}
          </label>
        )}
        <select
          value={props.phoneCodeState || '+374'}
          onChange={handlePhoneNumberChange}
          name='phone-number'
          id='phone-number'
          className='phone-number-span'>
          <option
            value='+374'
            selected={props.phoneCodeState === '+374' ? 'selected' : ''}>
            +374
          </option>
          <option
            value='+7'
            selected={props.phoneCodeState === '+7' ? 'selected' : ''}>
            +7
          </option>
          <option
            value='+1'
            selected={props.phoneCodeState === '+1' ? 'selected' : ''}>
            +1
          </option>
        </select>
        <input
          value={
            props.value
              ? props.value.includes('+374')
                ? props.value.replace('+374', '')
                : props.value.includes('+7')
                  ? props.value.replace('+7', '')
                  : props.value.includes('+1')
                    ? props.value.replace('+1', '')
                    : props.value
              : props.value || ''
          }
          onChange={handleChange}
          type={props.type}
          className='phone-number-input form-control'
          id={props.label}
          placeholder={props.placeholder}
        />

        {inputStatus === true ? (
          <span className={`Input-icon ${inputStatus ? 'success' : 'error'}`}>
            {/* <FontAwesomeIcon icon={faCheck} /> */}
          </span>
        ) : inputStatus === false ? (
          <span className={`Input-icon ${inputStatus ? 'success' : 'error'}`}>
            {/* <FontAwesomeIcon icon={faTimes} /> */}
          </span>
        ) : null}
        {inputStatus !== undefined ? (
          <span className='Input-rules'>{props.rules}</span>
        ) : null}
      </div>
    );
  } else {
    return <h1 key={props?.id}>There is no content to show in input</h1>;
  }
};

export default Input;
