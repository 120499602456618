import React, { useState, useEffect, useRef } from 'react';
import { InfoBox, SmallBox } from '../helpers/stats';
import { useLang } from '../helpers/language';
import * as API from '../helpers/api';
import 'chartjs-adapter-date-fns';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import config from '../config';

export default function Home() {
  const { lang } = useLang();
  const dateFilterStart = useRef(null);
  const dateFilterEnd = useRef(null);
  const [chartDates, setChartDates] = useState([]);
  const [chartData, setChartData] = useState({});

  let [data, setData] = useState('');

  function getOccurrence(array, value) {
    return array.filter((v) => v === value).length;
  }

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );

  useEffect(() => {
    (async () => {
      setChartDates([
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
      ]);
      setChartData({
        labels: chartDates,
        datasets: [
          {
            label: config.translate.menu.orders[lang],
            data: [data?.orders?.length],
            backgroundColor: 'rgba(255, 99, 132, 0,1)',
          },
          // {
          //   label: config.translate.menu.sales[lang],
          //   data: [data?.orders?.filter((item) => item.status === '4').length],
          //   backgroundColor: '#28a745',
          // },
          // {
          //   label: config.translate.menu.serving[lang],
          //   data: [data?.orders?.filter((item) => item.status === '2').length],
          //   backgroundColor: '#ffc107',
          // },
          // {
          //   label: config.translate.menu.failed[lang],
          //   data: [data?.orders?.filter((item) => item.status === '5').length],
          //   backgroundColor: '#dc3545',
          // },
        ],
      });
      const rawData = await API.get(config.api.chartsPage);
      if (rawData)
        setData({
          ...rawData,
          orders: rawData?.orders?.filter(
            ({ temprorary, date }) => !temprorary && dateIsValid(date)
          ),
        });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lastWeekFunction = () => {
      var date = new Date();
      var lastWeek = new Date(date.getTime());
      lastWeek.setDate(date.getDate() - 7);
      return lastWeek;
    };
    dateFilterStart.current.value = new Date(lastWeekFunction())
      .toISOString()
      .split('T')[0];
    dateFilterEnd.current.value = new Date().toISOString().split('T')[0];

    if (data) filterDate();
    // eslint-disable-next-line
  }, [data]);

  const filterDate = () => {
    const convertedDates = data?.orders?.map((item) =>
      new Date(item.date).setHours(0, 0, 0, 0)
    );
    let startDateRaw = new Date(dateFilterStart.current.value);
    let startDate = startDateRaw.setHours(0, 0, 0, 0);
    let endDateRaw = new Date(dateFilterEnd.current.value);
    let endDate = endDateRaw.setHours(0, 0, 0, 0);
    const filteredDates = convertedDates.filter(
      (date) => date >= startDate && date <= endDate
    );

    const startArray = convertedDates.indexOf(filteredDates[0]);
    const endArray = convertedDates.indexOf(
      filteredDates[filteredDates.length - 1]
    );

    let dates = data?.orders?.map((item) => {
      if (!dateIsValid(item.date)) {
      } else {
        let result = new Date(item.date).setHours(0, 0, 0, 0);
        result = new Date(result).toISOString();
        return result;
        // return item.date;
      }
    });

    //! Orders
    let orders = data?.orders?.map((item) => {
      if (!dateIsValid(item.date)) {
      } else {
        let result = new Date(item.date).setHours(0, 0, 0, 0);
        result = new Date(result).toISOString();
        return {
          y: getOccurrence(dates, result),
          x: new Date(item.date).setHours(0, 0, 0, 0),
        };
      }
    });

    const filteredOrders = [...orders];
    filteredOrders.splice(endArray + 1, filteredDates.length);
    filteredOrders.splice(0, startArray);

    //! Served
    let servedRaw = data?.orders?.filter((item) => item.status === '2');
    let served = servedRaw.map((item) => {
      let result = new Date(item.date).setHours(0, 0, 0, 0);
      result = new Date(result).toISOString();
      return {
        y: getOccurrence(dates, result),
        x: new Date(item.date).setHours(0, 0, 0, 0),
      };
    });
    const filteredServing = [...served];
    filteredServing.splice(endArray + 1, filteredDates.length);
    filteredServing.splice(0, startArray);

    //! Failed
    let failedRaw = data?.orders?.filter((item) => item.status === '5');
    let failed = failedRaw.map((item) => {
      let result = new Date(item.date).setHours(0, 0, 0, 0);
      result = new Date(result).toISOString();
      return {
        y: getOccurrence(dates, result),
        x: new Date(item.date).setHours(0, 0, 0, 0),
      };
    });
    const filteredFailed = [...failed];
    filteredFailed.splice(endArray + 1, filteredDates.length);
    filteredFailed.splice(0, startArray);

    setChartData({
      labels: filteredDates,
      datasets: [
        {
          label: config.translate.menu.orders[lang],
          data: filteredOrders,
          backgroundColor: '#000',
        },
        {
          label: config.translate.menu.serving[lang],
          data: filteredServing,
          backgroundColor: '#ffc107',
        },
        {
          label: config.translate.menu.failed[lang],
          data: filteredFailed,
          backgroundColor: '#dc3545',
        },
      ],
    });
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: config.translate.ordersCart[lang],
      },
    },
  };

  return (
    <>
      <div className='content-header'>
        <div className='container-fluid'>
          <div className='row mb-2'>
            <div className='col-sm-12 text-center'>
              <h1 className='m-0'>
                Deepmindsystems {config.translate.dashboard[lang]}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className='page-home container-fluid'>
        <div className='page-home-stats row justify-content-center'>
          <SmallBox
            header={config.translate.menu.storeCategories[lang]}
            value={data?.storeCategories?.length}
            // classname={'bg-info'}
            img={'/assets/logo_blue.svg'}
          />
          <SmallBox
            header={config.translate.menu.storeProducts[lang]}
            value={data?.storeProducts?.length}
            // classname={'bg-info'}
            img={'/assets/logo_blue.svg'}
          />
          <SmallBox
            header={config.translate.menu.kitchenCategories[lang]}
            value={data?.kitchenCategories?.length}
            // classname={'bg-danger'}
            img={'/assets/logo_red.svg'}
          />
          <SmallBox
            header={config.translate.menu.kitchenProducts[lang]}
            value={data?.kitchenProducts?.length}
            // classname={'bg-danger'}
            img={'/assets/logo_red.svg'}
          />
          {/* <SmallBox
            header={config.translate.menu.clients[lang]}
            value={data?.clients?.length}
            classname={''}
          />
          <SmallBox
            header={config.translate.menu.clientGroup[lang]}
            value={data?.clients?.length}
            classname={''}
          /> */}
        </div>
        <div className='page-home-stats row justify-content-center'>
          <InfoBox
            header={config.translate.menu.orders[lang]}
            value={data?.orders?.length}
            icon={'fas fa-shopping-cart'}
          />
          {/* //! orders that inprogress */}
          <InfoBox
            header={config.translate.menu.serving[lang]}
            value={
              data?.orders?.filter(
                (item) => item.status === '3' || item.status === '2'
              ).length
            }
            classname={'bg-warning'}
            icon={'fas fa-shopping-cart'}
          />
          {/* //! orders that failed */}
          <InfoBox
            header={config.translate.menu.failed[lang]}
            value={data?.orders?.filter((item) => item.status === '5').length}
            classname={'bg-danger'}
            icon={'fas fa-shopping-cart'}
          />
        </div>
        <div className='page-home-chart'>
          {chartData && chartDates.length > 1 ? (
            <Bar options={options} data={chartData} />
          ) : null}
          <div className='row text-center'>
            <div className='col-4 p-2'>
              Start:{' '}
              <input
                ref={dateFilterStart}
                type={'date'}
                max={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div className='col-4 p-2'>
              End:{' '}
              <input
                max={new Date().toISOString().split('T')[0]}
                ref={dateFilterEnd}
                type={'date'}
              />
            </div>
            <div className='col-4 p-2'>
              <button onClick={filterDate}>Filter</button>
            </div>
            {/* <button onClick={resetFilter}>Reset</button> */}
          </div>
        </div>
      </div>
    </>
  );
}
