import React, { useState, useEffect } from 'react';
import config from '../../config';
import { TableComponent } from './tableComponent';
import { useLang } from '../../helpers/language';
import Select from 'react-select';

export function ToDoComponent(props) {
  const [data, setData] = useState(props.data || []);
  const { lang } = useLang();
  const options = props.value.map((item) => {
    return { label: item.name.hy, value: item._id };
  });

  function addToList() {
    if (props.limit) {
      if (props.data.length < props.limit) {
        let temporaryCart = [...props?.data];
        let selected;
        props.value.forEach((item) => {
          if (item._id === data) {
            selected = { ...item };
          }
        });
        if (selected) temporaryCart.push(selected);
        props.setter(temporaryCart);
        setData(undefined);
      }
    } else {
      let temporaryCart = [...props?.data];
      let selected;
      props.value.forEach((item) => {
        if (item._id === data) {
          selected = { ...item };
        }
      });
      if (selected) temporaryCart.push(selected);
      props.setter(temporaryCart);
      setData(undefined);
    }
  }

  function onSelectChange(val) {
    setData(val.value);
  }

  return (
    <div className='form-element'>
      <label htmlFor=''>{props.title}</label>
      <Select
        name='form-field-name'
        options={options}
        onChange={onSelectChange}
        className={'form-element form-element-wide'}
      />
      <button
        className={`btn btn-primary
        }`}
        onClick={addToList}
        disabled={
          !(data?.length > 0) || (props.limit && props.data?.length >= props.limit)
        }
      >
        <i className='fas fa-download'></i>
        <span style={{ marginLeft: 10 }}>
          {config.translate.addToList[lang]}
        </span>
      </button>

      <TableComponent
        data={props.data}
        columns={props.columns}
        setter={props.setter}
        conditionalRowStyles={props.conditionalRowStyles}
        pagination={props.pagination}
        customStyles={props.customStyles}
      />
    </div>
  );
}
