import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useUser } from '../../helpers/userContext';
import { useLang } from '../../helpers/language';

export default function Product() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.product;
  const { user } = useUser();
  const { lang } = useLang();

  const [index, setIndex] = useState('');
  const [code, setCode] = useState('');

  const [nameEn, setNameEn] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameHy, setNameHy] = useState('');
  const [href, setHref] = useState('');

  const [descEn, setDescEn] = useState('');
  const [descRu, setDescRu] = useState('');
  const [descHy, setDescHy] = useState('');

  const [kg, setKg] = useState('true');

  const [subDescEn, setSubDescEn] = useState('');
  const [subDescRu, setSubDescRu] = useState('');
  const [subDescHy, setSubDescHy] = useState('');

  const [top, setTop] = useState(false);

  const [priceBasic, setPriceBasic] = useState(0);
  const [priceGroup1, setPriceGroup1] = useState(0);
  const [priceGroup2, setPriceGroup2] = useState(0);
  const [priceGroup3, setPriceGroup3] = useState(0);
  const [priceSale, setPriceSale] = useState(0);

  const [cannotPaidOnline, setCannotPaidOnline] = useState(false);
  const [notAvailable, setNotAvailable] = useState(false);

  const [relatedProducts, setRelatedProducts] = useState(undefined);
  const [relatedProductList, setRelatedProductList] = useState(null);

  const [categories, setCategories] = useState(undefined);
  const [categoryList, setCategoryList] = useState(null);

  const [tags, setTags] = useState(undefined);
  const [tagList, setTagList] = useState(null);

  const [image, setImage] = useState(undefined);

  const columns = {
    relatedProducts: [
      {
        name: config.translate.name[lang],
        selector: (row) => row.name?.hy,
      },
    ],
    categories: [
      {
        name: config.translate.name[lang],
        selector: (row) => row.name?.hy,
      },
    ],
    tags: [
      {
        name: config.translate.name[lang],
        selector: (row) => row.name?.hy,
      },
    ],
  };

  const modelSendToServer = {
    index,
    code,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    href,
    kg,
    top,
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    },
    subDesc: {
      en: subDescEn,
      ru: subDescRu,
      hy: subDescHy,
    },
    price: {
      basic: priceBasic,
      group1: priceGroup1,
      group2: priceGroup2,
      group3: priceGroup3,
      sale: priceSale,
    },
    cannotPaidOnline,
    notAvailable,
    relatedProduct_ids: relatedProducts
      ? [...relatedProducts.map((item) => item?._id)]
      : [],
    category_ids: categories ? [...categories.map((item) => item?._id)] : [],
    tag_ids: tags ? [...tags.map((item) => item?._id)] : [],
  };

  useEffect(() => {
    (async () => {
      let rawProducts = await API.get(config.api.product);
      if (rawProducts) setRelatedProductList(rawProducts);

      let rawTags = await API.get(config.api.tag);
      if (rawTags) setTagList(rawTags);

      let rawCategories = await API.get(config.api.category);
      if (rawCategories) setCategoryList(rawCategories);

      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });

        setIndex(currentItem[0]?.index);
        setCode(currentItem[0]?.code);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setHref(currentItem[0]?.href);
        setDescEn(currentItem[0]?.desc?.en);
        setDescRu(currentItem[0]?.desc?.ru);
        setDescHy(currentItem[0]?.desc?.hy);
        setKg(currentItem[0]?.kg);
        setTop(currentItem[0]?.top);
        setSubDescEn(currentItem[0]?.subDesc?.en);
        setSubDescRu(currentItem[0]?.subDesc?.ru);
        setSubDescHy(currentItem[0]?.subDesc?.hy);
        setPriceBasic(currentItem[0]?.price?.basic);
        setPriceGroup1(currentItem[0]?.price?.group1);
        setPriceGroup2(currentItem[0]?.price?.group2);
        setPriceGroup3(currentItem[0]?.price?.group3);
        setPriceSale(currentItem[0]?.price?.sale);
        setCannotPaidOnline(currentItem[0]?.cannotPaidOnline);
        setNotAvailable(currentItem[0]?.notAvailable);
        setRelatedProducts(currentItem[0]?.relatedProduct_ids);
        setCategories(currentItem[0]?.category_ids);
        // setTags(currentItem[0]?.tag_ids);

        let images = await getUploads(id, 'image');
        setImage(images && images[0]);

        let temporaryTags = [];
        tagList &&
          tagList.forEach((item) => {
            currentItem[0]?.tag_ids.forEach((innerItem) => {
              if (item._id === innerItem) {
                temporaryTags.push(item);
              }
            });
          });
        setTags(temporaryTags);
        let temporaryRelatedProducts = [];
        relatedProductList &&
          relatedProductList.forEach((item) => {
            currentItem[0]?.relatedProduct_ids.forEach((innerItem) => {
              if (item._id === innerItem) {
                temporaryRelatedProducts.push(item);
              }
            });
          });
        setRelatedProducts(temporaryRelatedProducts);
        let temporaryCategories = [];
        categoryList &&
          categoryList.forEach((item) => {
            currentItem[0]?.category_ids.forEach((innerItem) => {
              if (item._id === innerItem) {
                temporaryCategories.push(item);
              }
            });
          });
        setCategories(temporaryCategories);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        userRoles={['admin', 'editor']}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setIndex,
            type: 'number',
            usageType: 'number',
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
          },
          {
            value: code,
            setter: setCode,
            type: 'text',
            usageType: 'textfield',
            label: 'code',
            placeholder: 'code',
          },
          {
            value: href,
            setter: setHref,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.href[lang],
            placeholder: 'code',
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'hy',
          },
          {
            value: descHy,
            setter: setDescHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'hy',
          },
          {
            value: subDescHy,
            setter: setSubDescHy,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.subDesc[lang],
            placeholder: config.translate.subDesc[lang],
            lang: 'hy',
            fullWidth: true,
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'ru',
          },
          {
            value: descRu,
            setter: setDescRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'ru',
          },
          {
            value: subDescRu,
            setter: setSubDescRu,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.subDesc[lang],
            placeholder: config.translate.subDesc[lang],
            lang: 'ru',
            fullWidth: true,
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'en',
          },
          {
            value: descEn,
            setter: setDescEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'en',
          },
          {
            value: subDescEn,
            setter: setSubDescEn,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.subDesc[lang],
            placeholder: config.translate.subDesc[lang],
            lang: 'en',
            fullWidth: true,
          },
          {
            value: priceBasic,
            setter: setPriceBasic,
            type: 'number',
            usageType: 'number',
            label: config.translate.priceBasic[lang],
            placeholder: config.translate.priceBasic[lang],
          },
          {
            value: priceGroup1,
            setter: setPriceGroup1,
            type: 'number',
            usageType: 'number',
            label: config.translate.group1[lang],
            placeholder: config.translate.group1[lang],
          },
          {
            value: priceGroup2,
            setter: setPriceGroup2,
            type: 'number',
            usageType: 'number',
            label: config.translate.group2[lang],
            placeholder: config.translate.group2[lang],
          },
          {
            value: priceGroup3,
            setter: setPriceGroup3,
            type: 'number',
            usageType: 'number',
            label: config.translate.group3[lang],
            placeholder: config.translate.group3[lang],
          },
          {
            value: priceSale,
            setter: setPriceSale,
            type: 'number',
            usageType: 'number',
            label: config.translate.sale[lang],
            placeholder: config.translate.sale[lang],
          },
          {
            value: cannotPaidOnline,
            setter: setCannotPaidOnline,
            radioValue: [
              { value: false, name: 'Online' },
              { value: true, name: 'Offline' },
            ],
            type: 'radio',
            usageType: 'radio',
            label: config.translate.cannotPayOnline[lang],
          },
          {
            value: notAvailable,
            setter: setNotAvailable,
            radioValue: [
              { value: false, name: 'Առկա է' },
              { value: true, name: 'Առկա ՉԷ' },
            ],
            type: 'radio',
            usageType: 'radio',
            label: config.translate.notAvailable[lang],
          },
          {
            value: top,
            setter: setTop,
            radioValue: [
              { value: false, name: 'Սովորական' },
              { value: true, name: 'Հիթ' },
            ],
            type: 'radio',
            usageType: 'radio',
            label: config.translate.notAvailable[lang],
          },
          {
            value: kg,
            setter: setKg,
            radioValue: [
              { value: false, name: 'Հատ' },
              { value: true, name: 'Կիլոգրամ' },
            ],
            type: 'radio',
            usageType: 'radio',
            label: config.translate.notAvailable[lang],
          },
        ]}
        toDoComponent={[
          {
            title: 'Կից ապրանքներ',
            data: relatedProducts,
            value: relatedProductList,
            columns: columns.relatedProducts,
            setter: setRelatedProducts,
            width: '220px',
            limit: 3,
            // customStyles: true,
          },
          {
            title: 'Կատեգորիաներ',
            data: categories,
            value: categoryList,
            columns: columns.categories,
            setter: setCategories,
            pagination: false,
            width: '220px',
            // customStyles: true,
          },
          {
            title: 'Պիտակ',
            data: tags,
            value: tagList,
            columns: columns.tags,
            setter: setTags,
            pagination: false,
            width: '220px',
            // customStyles: true,
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: image,
            filesUploadLimit: 1,
            adminMode: user.role !== 'editor',
            acceptedFiles: '.jpg, .jpeg, .png',
            thumbSizeWidth: 270,
            responsiveImages: true,
          },
        ]}
      />
    );
}
