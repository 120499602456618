import React, { useState, useEffect } from 'react';
import config from '../../config';
import Dropzone, { useDropzone } from 'react-dropzone';
import * as API from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useLang } from '../../helpers/language';
import Loading from './loading';

export function FilesComponent(props) {
  const [loading, setLoading] = useState(false);
  var disabled = false;
  var fileId = '';
  const history = useHistory();
  const { lang } = useLang();

  useEffect(() => {
    // setLoading(false);
  }, [loading]);

  // eslint-disable-next-line
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
      },
    });

  if (props.file === undefined || props.file === '') {
    disabled = true;
  } else if (props.file) {
    fileId = props.file._id;
  }

  async function removeFile(e) {
    setLoading(true);
    try {
      await API.remove(config.api.uploads, e.currentTarget.id);
      setLoading(false);
      props.setBusy(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSave(files, lang, destiny) {
    setLoading(true);
    if (props.id === 'add') {
      const rowData = await API.post(props.route, props.modelSendToServer);
      let id = rowData[0]._id;
      await history.push(`${id}`);
      let result = await API.upload(
        id,
        files,
        props.lang,
        props.destiny,
        props.thumbSizeWidth,
        props.thumbSizeHeight,
        props.responsiveImages
      );
      if (result) {
        setLoading(false);
      }
    } else {
      try {
        await API.update(props.route, props.id, props.modelSendToServer);
        try {
          let result = await API.upload(
            props.id,
            files,
            props.lang,
            props.destiny,
            props.thumbSizeWidth,
            props.thumbSizeHeight,
            props.responsiveImages
          );
          if (result) {
            setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        console.log(error);
      }
    }
    props.setBusy(true);
  }
  const openInNewTab = (e) => {
    let win = window.open(`${config.api.API_URL}/${props.file.path}`, '_blank');
    win.focus();
  };

  if (loading) return <Loading />;
  else
    return (
      <>
        <div className='col-md-12'>
          <div className='card card-secondary'>
            <div className='card-header'>
              <h3 className='card-title'>
                {props.destiny
                  ? props.destiny.charAt(0).toUpperCase() +
                    props.destiny.slice(1)
                  : 'Files'}{' '}
                section
              </h3>
            </div>
            <div className='card-body'>
              {!disabled ? (
                props.file.type === 'application/pdf' ? (
                  <embed
                    type='application/pdf'
                    width='100%'
                    src={`${config.api.API_URL}/${props.file.path}`}
                  />
                ) : props.file.type === 'image/png' ||
                  props.file.type === 'image/jpg' ||
                  props.file.type === 'image/jpeg' ||
                  props.file.type === 'image/JPEG' ? (
                  <img
                    className='image-section'
                    src={`${config.api.API_URL}/${props.file.path}`}
                    alt=''
                  />
                ) : (
                  <p>{props.file.name}</p>
                )
              ) : (
                <Dropzone
                  accept={props.acceptedFiles}
                  onDrop={(acceptedFiles) => handleSave(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section className='dropzone'>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>{config.translate.dropzoneText['hy']}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </div>
            <div className='card-footer'>
              <div className='btn-group'>
                <button
                  onClick={openInNewTab}
                  disabled={props.slider || disabled}
                  display={props.slider ? 'none' : 'inline'}
                  type='button'
                  className='btn btn-default'>
                  <i className='fas fa-share'></i>
                  <span style={{ marginLeft: 10 }}>
                    {config.translate.open['hy']}
                  </span>
                </button>
                {props.adminMode ? (
                  <button
                    onClick={removeFile}
                    disabled={disabled}
                    type='button'
                    id={fileId}
                    className='btn btn-danger'>
                    <i className='fas fa-trash'></i>
                    <span style={{ marginLeft: 10 }}>
                      {config.translate.remove['hy']}
                    </span>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
